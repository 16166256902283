import Section from '@/components/Shared/Section';
import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import DevicesList from './DevicesList';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import LoadingAlarm from './LoadingAlarm';
import CircularWithBasePath from '@/components/Shared/ProgressWithLabel/CircularWithBasePath';
import { CHANNELS } from '@/shared/constants';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';

export const FLEET_GENERAL_QUERY_WIDTH = 1340;

export default function FleetGeneral() {
  const [openDialog, setOpenDialog] = useState(false);
  const { data, isLoading, isFetching, isError } = useGetFleetQuery();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);

  const SafeBody = withErrorLoadingManagement(() => (
    <>
      {[
        {
          number: data.data.alerts.priority || 0,
          label: t('devicesHave') + ' ' + t('errors').toLowerCase(),
          color: 'error',
        },
        {
          number: data.data.alerts.postponable || 0,
          label: t('devicesHave') + ' ' + t(CHANNELS.ALARMS).toLowerCase(),
          color: 'warning',
        },
        {
          number: data.data.alerts.none || 0,
          label: t('devicesWithoutAlarmsOrErrors'),
          color: 'success',
        },
        {
          number: data.data.devicesWithoutTelemetry,
          label: 'No telemetry system',
          color: 'inherit',
        },
      ].map(
        (
          v: {
            label: string;
            number: number;
            color: 'warning' | 'error' | 'success' | string;
          },
          i: number,
          list
        ) => (
          <AreaContainer key={v.label}>
            <AreaBody>
              <Box
                sx={{
                  gap: 2,
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <CircularWithBasePath
                  key={v.label}
                  size={70}
                  color={v.color as 'error' | 'warning' | 'success' | 'inherit'}
                  innerLabel={v.number}
                  innerLabelStyle={{
                    fontSize: 20,
                    fontWeight: '800',
                    color: ['warning', 'error', 'success'].includes(v.color)
                      ? theme.palette[v.color as 'warning' | 'error' | 'success'].light
                      : theme.palette.text.secondary,
                  }}
                  circleContainerStyle={{ padding: 0 }}
                  thickness={5}
                  value={(v.number * 100) / list.reduce((p, c) => c.number + p, 0)}
                  variant="determinate"
                />
                <Typography sx={{ lineHeight: '1' }} variant="subtitle2">
                  {v.label}
                </Typography>
              </Box>
            </AreaBody>
          </AreaContainer>
        )
      )}
    </>
  ));

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: () => setOpenDialog(false),
    tabs: [
      {
        id: 'numberAndKindOfDevices',
        label: t('numberAndKindOfDevices'),
        body: <DevicesList isDialog={true} handleDialog={setOpenDialog} />,
      },
    ],
    styles: {
      bodyContainer: {
        p: 0,
      },
    },
  });

  return (
    <Section>
      {dialog}
      <Typography variant="h5">{t('fleetGeneral')}</Typography>
      <Box display="grid" gridTemplateColumns={'1.3fr 0.8fr'} gridTemplateRows={'auto auto'} gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('numberAndKindOfDevices')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/devices')}>
            {t('seeAll')}
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('alarmsAndErrors')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/alarms-errors')}>
            {t('seeAll')}
          </Button>
        </Box>
        <Card sx={{ flexGrow: 1 }}>
          <DevicesList isDialog={false} handleDialog={setOpenDialog} />
        </Card>
        <Card sx={{ p: 1.5, bgcolor: 'white' }}>
          {' '}
          {/* Adjust padding for the overall container */}
          <Box
            display="grid"
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateRows={'auto auto'}
            gap={'0.5rem'}
            sx={{ height: '100%' }}
            {...(matches ? { height: 'fit-content' } : {})}
          >
            <SafeBody isError={isError} isLoading={isLoading || isFetching} LoadingComponent={<LoadingAlarm />} />
          </Box>
        </Card>
      </Box>
    </Section>
  );
}
